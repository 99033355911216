import dayjs from 'dayjs';
import {DEMO_DATE_FORMAT, LANGUAGE_CONSTANT, PLACEHOLDER} from '../../const';
import {isDemoMode, isUnsupportedLanguage} from '../../utils';
import {LANG_SETTING} from '../../settings';

export const setLocalMonthName = (date, formattedDate) => {
    const month = dayjs(date).format(PLACEHOLDER.MonthFormat);

    return formattedDate.replace(month, LANGUAGE_CONSTANT[TL_PRICE_MODULE_LANG].month[month]);
};

export const getDateText = (date) => {
    let dateFormatted = dayjs(date).format(LANG_SETTING[TL_PRICE_MODULE_LANG].DateFormat);

    if (TL_PRICE_MODULE_LANG === 'ru') {
        dateFormatted = setLocalMonthName(date, dateFormatted);
    }

    return LANG_SETTING[TL_PRICE_MODULE_LANG].DateText.replace(PLACEHOLDER.DateFormat, dateFormatted);
};

export const getLangDate = (date) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return (date) ? dayjs(date).format(DEMO_DATE_FORMAT) : date;
    }

    return (date) ? getDateText(date) : date;
};
